.page-body {
      padding-top: 8vw;
      padding-bottom: 8vw;
      padding-left: 15%;
      padding-right: 15%;
      font-family: Poppins;
}

.page-head {}

.page-head h1 {
      font-size: 4rem !important;
}

.page-head h2 {
      font-size: 3rem !important;
}

.page-content {
      /* padding: 2rem 0rem 1rem 0rem; */
}

.page-part {
      padding: 8rem 0rem 1rem 0rem;
}

.part-head {}

.part-body {}

p {
      margin-bottom: 2rem;
}


.querywall-message-textarea {
      max-width: 99%;
      min-width: 99%;
      resize: none;
      background-color: var(--eerie-black);
      color: var(--text-white-2);
      font-size: 1.1rem;
}

.send-message-btn {
      /* margin-left: 10px; */
      padding: 5px 10px;
      cursor: pointer;
      background-color: var(--lawn-green);
      /* background-color: rgb(150, 228, 150); */
      /* color: var(--dim-gray); */
      color: var(--text-white);
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: 500;
      border: 2px solid var(--lawn-green)
}

.send-message-btn:disabled {
      background-color: var(--lawn-green0);
      border: 2px solid var(--dim-gray);
      cursor: not-allowed;
}

.send-message-btn:disabled:hover {
      background-color: var(--lawn-green0);
      cursor: not-allowed;
      border: 2px solid var(--dim-gray)
}

.send-message-btn:hover {
      background-color: var(--lawn-green5);
      border: 2px solid var(--lawn-green);
    
}

.purchase-guide-step-img-frame {
      max-width: 100%;
      padding: 0px 5%;
      display: flex;
      justify-content: center;
}

.purchase-guide-step-img {
     
      border: 2px solid var(--lawn-green);
      border-radius: 16px;
      max-width: 80%;
}