:root {
      /* CSS HEX */
      --text-white: #ffffff;
      --text-white-2: #e8f7e8;
      --nyanza: #d6f5d7ff;
      --dim-gray: #656565ff;
      --lawn-green: #80e614ff;
      --lawn-greend: #80e614cc;
      --lawn-green5: #80e61488;
      --lawn-green0: #80e61401;
      --eerie-black: #222222ff;
      --pistachio: #abda7aff;
      --pistachio6: #abda7a88;
      --pistachio2: #abda7a33;
      --intermediate: #E6CA14ff;
      --intermediate6: #E6CA1488;
      --advanced: #f54939;
      --advanced6: #f56a5d5c;
      --link: #538feb;
}