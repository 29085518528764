.video-loading {
      padding-top: 4rem;

}



.video-loading-shape-text {
      display: grid;
      justify-content: center;
      text-align: center;
}

.video-loading-shape-frame {
      display: flex;
      justify-content: center;
      width: 100%;
}

.video-loading-shape-box {
      width: 600px;
      height: 250px;
      display: flex;
      justify-content: center;
      /* position: relative */
}

.vid-img {
      position: absolute;

      margin: auto;
}

.video-loading-circle {

      height: 70px;
      top: 230px;
}

@media (prefers-reduced-motion: no-preference) {
      .video-loading-circle {
            animation: waiting-spin-2 calc(1.8s / var(--d, 1.2)) infinite;
            /* cubic-bezier(1, 0, 1, 1) */
      }



}

@keyframes waiting-spin-2 {
      from {
            transform: rotate(0deg);
      }

      to {
            transform: rotate(360deg);
      }
      
}