 .App {
   /* text-align: center; */
 }

 .App-logo {
   height: 40vmin;
   pointer-events: none;
 }

 @media (prefers-reduced-motion: no-preference) {
   .App-logo {
     animation: App-logo-spin infinite 20s linear;
   }
 }

 .App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
 }

 .App-link {
   color: #61dafb;
 }

 @keyframes App-logo-spin {
   from {
     transform: rotate(0deg);
   }

   to {
     transform: rotate(360deg);
   }
 }


 .navbar-frame {
   background-color: var(--dim-gray);
   box-sizing: border-box;
   /* padding: 24px */
 }

 .nav-br-p {
   font-size: 1.25rem;
   font-weight: 700;
   font-family: Poppins;
   color: var(--text-white);
   text-decoration: none;
 }

 .navbar-brand {
   text-decoration: none;
   margin: 24px;
 }

 .vm {
   vertical-align: middle;
 }

 .bl {
   vertical-align: baseline;
 }