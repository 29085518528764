.admin-form{
      max-width: 50%;
}


.admin-form div {
      display: flex;
      justify-content: space-between;
}

.form-btns {
      display: flex;
      justify-content: end !important;
}


.admin-table {
      /* display: grid; */
      border-collapse: collapse;
      width: 100%;
}

.admin-table  tbody {
      display: table-header-group;
}
.admin-table  tr {
      display: table-row !important;
}
.admin-table th {
      border: 1px white solid;
      padding: 4px 8px;
}
.admin-table td {
      border: 1px white solid;
      padding: 4px 8px;
}

/* .admin-table th td {
      e
      
} */