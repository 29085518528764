.pol-screen {
      position: fixed;
      /* width: 100%; */
      height: 100%;
      top: 0;
      left: 0;
      background-color: #123808aa;
      z-index: 2;
      padding: 10%;
      padding-top: 4%;
      transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
      overflow-y: scroll;
      padding-bottom: 2rem;
}

.pol-screen::-webkit-scrollbar {
      display: none;
}

.pol-frame {
      /* width: 80%; */
      padding-bottom: 8rem;
}

.pol-close-box {
      padding: 0.5rem;
      display: flex;
      justify-content: end;
}

.pol-close-box-2 {
      display: none;

}

.pol-close-btn {
      color: var(--text-white);
      font-size: 2rem;
      font-weight: 700;
      cursor: pointer;
      width: fit-content;
}

.pol-box {
      border: 2px solid #71e753;
      border-radius: 1rem;
      background-color: #1b2d18;
      padding: 2rem;
}

.pol-box-body {
      display: flex;
}

.pol-half {
      width: 50%;
      padding: 1rem;
}

.pol-info {
      padding-right: 2rem;
}

.pol-action {
      padding-top: 1rem;
}

.pol-action-discount-form {
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      display: flex;
}

.pol-action-discount-form input {
      background-color: var(--dim-gray);
      border-radius: 8px;
      color: var(--text-white);
}

.apply-btn {
      background-color: var(--pistachio6);
      border: 2px solid #abda7a00;
      padding: 8px;
      padding-bottom: 10px;
      border-radius: 8px;
      /* margin: 4px; */
      vertical-align: middle;
      display: flex;
      cursor: pointer;
      color: var(--nyanza);
      transition: 300ms;
}

.apply-btn:hover {
      background-color: #abda7aaa;
      border: 2px solid var(--pistachio6);
      text-decoration: underline;
}

.pol-action-price h3 {
      display: flex;
      height: 2rem;
      transform: 300ms;
      /* margin: 0px;
      padding: 1rem 0px; */
}

.pol-action-discount-result {
      height: 1rem;
}

.pol-action-discount-result-error {
      color: #ff8a8a;
      border-bottom: 4px solid #ff000050;
}

.pol-action-discount-result-ok {
      color: #98ff8a;
      border-bottom: 4px solid #33ff0050;
}

.pol-action-term-frame {
      padding: 1rem 0px;
}

.pol-action-term-box input {
      margin-left: 0px;
}

.pol-action-cta-box-row {
      display: flex;
      justify-content: center;
}

.pol-action-cta-box-row button {
      margin: 8px 0px !important
}

.pol-action-cta-box-row-loading {
      position: absolute;
      padding-top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
}

.pol-box-footer {
      padding: 0rem 12rem;
}

.pol-guide {
      text-align: center;
}



@media only screen and (max-width: 768px) {
      .pol-screen {
            padding: 5%;
            width: unset;
      }

      .pol-frame {
            /* width: 90%; */
            padding-bottom: 4rem;
      }

      .pol-box {
            display: block;
      }

      .pol-box-body {
            display: block;
      }

      .pol-half {
            width: 100%;
            padding: 0rem;
      }

      .pol-info {
            padding-right: 0rem;
      }

      .pol-box-footer {
            padding: 2rem 1rem 0rem 1rem;
      }

      .pol-close-box-2 {
            display: block;
            padding: 0.5rem;
            display: flex;
            justify-content: center;
      }

      .pol-close-btn {
            font-size: 1.4rem !important;
      }
}


@media only screen and (min-width: 1200px) {
      .pol-action {
            padding-left: 8rem;
            padding-top: 0px;
      }
}

.wating-box {
      padding: 0px 6px;
}

.waiting-logo {
      height: 4vmin;
      pointer-events: none;
}

.waiting-logo-2 {

      pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
      .waiting-logo {
            animation: waiting-spin calc(1.8s / var(--d, 1.2)) infinite;
            /* cubic-bezier(1, 0, 1, 1) */
      }

      .waiting-logo-2 {
            animation: waiting-spin calc(1.8s / var(--d, 1.2)) infinite;
            /* cubic-bezier(1, 0, 1, 1) */
      }

}


@keyframes waiting-spin {
      from {
            transform: rotate(0deg);
      }

      to {
            transform: rotate(360deg);
      }
}