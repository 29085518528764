html{
      scroll-behavior: smooth;
}

body {
      background-color: var(--eerie-black);
      color: var(--text-white);
      margin: 0px;

}

a {
      color: var(--link);
}


.loading-frame {
      width: 100%;
      justify-content: center;
      display: flex;
      padding: 200px 0px;


}


.loding-asset {
      animation: lds-ring calc(1.2s / var(--d, 1)) cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transition: all 1s ease-in-out;
}

@keyframes lds-ring {
      0% {
            transform: rotate(0deg);
      }

      100% {
            transform: rotate(360deg)
      }
}

.see-videos-btn{
      margin-bottom: 2rem;
      padding: 12px;
            background-color: #ffffff40;
            border: none;
            color: var(--text-white);
            font-size: 20px;
            border-radius: 8px;
            cursor: pointer;
            font-weight: 500;
}

.display-none{
      display: none;
}

.video-container {
       width: 100%;
      height: 100%;
}

video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
      /* Not necessary in Chrome */
}
 