.public-agent-profile {
      font-family: Arial, sans-serif;
      /* max-width: 1000px; */
      margin: 0 auto;
      /* padding: 20px; */
      aspect-ratio: 16/9;
      margin-bottom: 60px;
}


.private-agent-profile {
      font-family: Arial, sans-serif;
      margin: 0 auto;
      padding: 40px;
      aspect-ratio: 16/9;
      margin-bottom: 60px;

}


.banner-section {
      position: relative;
      background-color: #f5f5f5;
}

.agent-info {
      padding: 20px 20%;
}

.agent-info h3 {
      font-weight: 100;
}

.bio-section {
      margin-top: 20px;
      font-size: 18px;
      text-align: start;
      padding: 20px 20%;
}


.social-links-display {
      margin-top: 10px;
      text-align: center;
}

.social-links-section {
      /* padding: 20px 20%; */
}

.social-links-section ul {
      margin-top: 10px;
      text-align: start;

}

.social-links-section h3 {
      margin-top: 50px;
      text-align: start;
}

.social-links-section a {
      color: #007bff;
      text-decoration: none;
      font-size: 16px;
}

.social-links-section a:hover {
      text-decoration: underline;
}

.social-link-row {
      padding: 14px;
      display: block;
}

.social-link-row-hov {
      /* margin: 14px; */
}

.social-link-row-hov:hover {
      background-color: #99999944;
}



.add-socila-link-container {
      display: flex;
      justify-content: center;
}

.add-socila-link-frame {
      margin-top: 40px;
      border: 1px white solid;
      padding: 40px;
      padding-bottom: 20px;

      width: fit-content;
}

.add-socila-link-box {
      display: grid;
      justify-content: center;
      align-items: center;
}

.add-socila-link-box-row {
      padding: 10px;

}

textarea {
      width: -webkit-fill-available;
}

.bio-btn-frame {
      display: flex;
      justify-content: end;
}


@media only screen and (max-width: 768px) {
      .agent-info {
            padding: 0px 0px;
      }

      .info-display {

            text-align: center;
            padding: 0px 2rem;
      }

      .bio-section {

            padding: 2rem 0px;
      }

      .social-links-section {
            padding: 0px 0%;
      }

      .social-link-row {
            display: block;
      }

      .bio-btn-frame {
            display: block;
      }

      .bio-btn-frame-input {
            width: 100%;
      }
      .bio-btn-frame-input input {
            width: -webkit-fill-available;
      }

      .bio-btn-frame-btn {
            display: flex;
            width: 100%;
            justify-content: end;
            padding-top: 6px;
      }
}


@media only screen and (max-width: 368px) {
      .social-link-row div {
            display: block !important;
            padding-bottom: 20px;
      }
}

/* Part for Forms */

.form-container {
      text-align: center;
      padding: 2rem 1rem;
}

.form-container form button {
      margin: 16px;
}

.form-group {
      justify-content: center;
      display: grid;
      padding-top: 20px;
      width: auto;
}

.form-group label {
      justify-self: start;
      align-self: start;
      padding-bottom: 6px;
}

.reg-btn {
      background-color: var(--lawn-green);
      border-radius: 12px;
      padding: 0.5rem 1rem 0.5rem 1rem;
      color: var(--dim-gray);
      margin: 0.5rem 0.5rem 0.5rem 0.5rem;
      font-family: Poppins;
      font-size: 1rem;
      font-weight: 600;
      text-wrap: nowrap;
      cursor: pointer;
      border: none;
}

.reg-btn:hover {
      background-color: rgb(143, 240, 39);
      color: #414141
}


.upd-btn {
      margin-left: 10px;
      padding: 5px 10px;
      cursor: pointer;
      background-color: rgb(60, 48, 230);
      /* background-color: rgb(150, 228, 150); */
      /* color: var(--dim-gray); */
      color: rgb(213, 212, 231);
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: 500;
}

.upd-btn:hover {
      /* background-color: rgb(181, 224, 181); */
      background-color: rgb(60, 48, 230);
      color: rgb(236, 236, 236);
}

.agent-info-row {
      text-align: start;
      display: grid;
      margin-bottom: 10px;
}

.agent-info-row h3 {
      margin-bottom: 8px;
}

.res-success {
      color: green;
      margin-bottom: 8px;
}

.res-error {
      color: red;
      margin-bottom: 8px;
}

.txt-sm {
      font-size: small;
}

.ps-note {
      padding: 2rem 2rem;
      text-align: center;
}