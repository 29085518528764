.home {
      width: 100%;
      /* padding-right: 16px; */
}

.home-inside {
      padding-right: 16px;
      justify-items: center;
}

.title-main {
      width: inherit;
      text-align: center;
      font-size: 3.5rem;
      line-height: 4.5rem;
      font-family: Poppins;
}

.searchbar-frame {

      width: 100%;
      text-align: center;
}

.searchbar-input {
      vertical-align: top;
      display: inline-flex;
      padding: 1rem;
      text-align: center;
      height: 64px;
}



.searchbar-input-text {
      width: 40vw;
      font-size: 1.2rem;
      font-weight: 500;
      height: inherit;
      border: 6px solid var(--pistachio);
      border-radius: 12px;
      background-color: #656565;
      color: var(--text-white);
      box-sizing: border-box;
      padding-left: 6px;
}

.searchbar-input-text:focus {
      outline: none;
      border: 6px solid var(--lawn-green);
}

.searchbar-input-text::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #adadad;
      opacity: 1;
      /* Firefox */
}

.searchbar-input-btn {

      font-size: 1.5rem;
      font-weight: 700;
      height: inherit;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 0px;
      border: 2px solid var(--lawn-green);
      border-radius: 12px;
      background-color: var(--lawn-green);
      color: var(--text-white);
      margin-left: 20px;
      cursor: pointer;
}






.course-frame {
      background-color: var(--dim-gray);
      display: flex;
      flex-wrap: wrap;
      margin-top: 5rem;
      padding-bottom: 2rem;
      justify-content: center;
      align-items: center;
}