.card-frame {
      margin: 0px;
      padding: 1.4rem;
      padding-bottom: 0px;
      width: 24vw;
      min-width: 24rem;
      max-width: 24vw;
      box-sizing: border-box;
}

.card-frame-center {
      padding: 1.4rem 0.4rem;
}


.card-border {
      min-height: 300px;
      background-color: var(--eerie-black);
      border-radius: 32px;
      padding: 16px;
      padding-bottom: 20px;

}

.card-img {
      border-radius: 16px;
      width: 100%;
      background-color: var(--dim-gray);

}

.card-img img {
      width: 100%;
      border-radius: 16px;
      mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
      -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 1) 100%);
}

.card-banner {
      width: 100%;
      border-radius: 16px;
      aspect-ratio: 16/9 !important;
}

.card-banner-mask { 
      mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
      -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 1) 100%);
}
.card-banner-mask-light { 
      mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
      -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 1) 17%);
}


.card-title-frame {
      height: 6rem;
}

.card-title {
      font-size: 1.75rem;
      font-weight: 700;
      color: var(--text-white);
      font-family: Poppins;
      padding-top: 0.7rem;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
}

.card-shortDescription-frame {
      height: 6rem;
}

.card-shortDescription {
      font-size: 1rem;
      font-weight: 500;
      color: var(--nyanza);
      font-family: Poppins;
      padding-top: 0.6rem;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
}

.techs-bar {
      margin-top: 0.5rem;
      width: 100%;
      display: flex;
      overflow-y: hidden;
      overflow-x: scroll;
      mask-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0.65) 100%);
      -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.3) 0%,
                  rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 1) 100%);
}

.techs-bar::-webkit-scrollbar {
      display: none;
}

.techs {
      background-color: var(--pistachio6);
      border-radius: 12px;
      padding: 0.5rem;
      color: var(--text--white);
      margin: 0.5rem 0.5rem 0.5rem 0px;
      font-family: Poppins;
      font-size: 1.1rem;
      font-weight: 500;
      text-wrap: nowrap;
}

.card-info-bar {

      margin-top: 0.5rem;
      width: 100%;
      display: flex;
      overflow-y: hidden;
      overflow-x: scroll;
      -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 1) 100%);
      /* mask-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0.65) 100%);
      -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.3) 0%,
                  rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 1) 100%); */
}

.card-info-bar::-webkit-scrollbar {
      display: none;
}

.card-info {

      background-color: var(--pistachio6);
      border-radius: 9px;
      padding: 0.5rem;
      color: var (--text--white);
      margin: 0.7rem 0.7rem 0.7rem 0px;
      width: fit-content;
      line-height: 1.5rem;
      font-family: Poppins;
      font-size: 1.1rem;
      font-weight: 300;
      text-wrap: nowrap;
      text-transform: capitalize;
}


.card-info-old {

      background-color: var(--pistachio2);
      border-radius: 0.4rem;
      padding: 0.5rem;
      color: var (--text--white);
      margin: 0.7rem 0.7rem 0.7rem 0px;
      font-family: Poppins;
      font-size: 0.8rem;
      font-weight: 500;
      text-wrap: nowrap;
      text-transform: capitalize;
}

.dif-lvl-beg {
      /* border: 2px solid var(--lawn-green); */
      background-color: var(--pistachio6);
      padding: 0.4rem;
}



.dif-lvl-int {
      border: 2px solid var(--intermediate);
      background-color: var(--intermediate6);
      padding: 0.4rem;
}

.dif-lvl-adv {
      border: 2px solid var(--advanced);
      background-color: var(--advanced6);
      padding: 0.4rem;
}

.dif-lvl-int span img {
      margin-bottom: -4px;
}

.dif-lvl-adv span img {
      margin-bottom: -4px;
}

.dif-lvl-beg span img {
      margin-bottom: -4px;
}


.info-icon {
      margin-top: 2px;
      max-width: 16px;
      margin-bottom: -1px;
}

.card-foot-bar {
      margin-top: 0.8rem;
      display: flex;
      justify-content: space-between;
}

.card-foot-price-frame {
      /* width: -webkit-fill-available; */
      display: flex;
      max-width: 60%;
      overflow-x: scroll;
      margin-right: 6px;
      -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 1) 100%);
}

.card-foot-price-frame::-webkit-scrollbar {
      display: none;
}

.card-foot-price-frame:first-child {
      border-radius: 12px 12px 12px 16px;
}

.card-foot-price {
      display: flex;
      border-radius: 12px 12px 12px 12px;
      background-color: var(--pistachio6);
      color: var(--nyanza);
      padding: 0rem 0.8rem 0rem 0.5rem;
      font-family: Poppins;
      font-size: 1.2rem;
      font-weight: 700;
      text-wrap: nowrap;
      line-height: 2;
      /* cursor: pointer; */
      width: fit-content;
      margin-right: 6px;
      /* max-width: 40%; */
      /* overflow-x: scroll; */
      mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.6) 0%, var(--nyanza) 100%);
      -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.6) 0%, var(--nyanza) 100%);
}

.card-foot-price-old {
      display: flex;
      border-radius: 12px 12px 12px 12px;
      background-color: var(--nyanza);
      color: var(--eerie-black);
      padding: 0rem 0.8rem 0rem 0.5rem;
      font-family: Poppins;
      font-size: 1.2rem;
      font-weight: 700;
      text-wrap: nowrap;
      line-height: 2;
      /* cursor: pointer; */
      width: fit-content;
      margin-right: 6px;
      /* max-width: 40%; */
      /* overflow-x: scroll; */
      mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.6) 0%, var(--nyanza) 100%);
      -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.6) 0%, var(--nyanza) 100%);
}

.card-foot-price::-webkit-scrollbar {
      display: none;
}

.card-foot-price-text {
      /* line-height: 1.2rem; */
      cursor: default;
}

.card-foot-price-logo {
      /* line-height: 1.2rem; */
      margin-right: 0.3rem;
}

.btcCoin {
      max-width: 1.1rem;
}

.read-ribbon {
      display: flex;
      justify-content: space-between;
      width: 60%;
}

.card-foot-ribbon {
      display: flex;
      vertical-align: middle;
      cursor: pointer;
}

.card-foot-Readmore {
      padding: 0rem 0.4rem;
}

.card-foot-Readmore-link {
      color: var(--lawn-green);
      font-family: Poppins;
      font-size: 1.4rem;
      font-weight: 400;
      text-wrap: nowrap;
}

@media only screen and (max-width: 768px) {
      .card-foot-Readmore-link {
            font-size: 1.4rem;
            text-wrap: pretty;
      }
}