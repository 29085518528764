:root {
      --primary-color: #2A9D8F;
      --secondary-color: #E9C46A;
      --accent-color: #F4A261;
      --text-color: #e2ebdf;
      --background-color: #F1FAEE;
}

.my-body * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: Poppins;
}

.my-body-2 * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: Poppins;
}

.my-body {
      background-color: var(--dim-gray);
      color: var(--text-color);
      line-height: 1.6;
      background-image: url(../../../../public/Cheerful_robot_making_crypto.webp);
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 30%;
      background-blend-mode: lighten;
}

.my-header {
      background-color: var(--eerie-black);
      padding: 4rem 2rem;
      text-align: center;
      color: var(--text-white-2)
}

.header-content h1 {
      font-size: 3.5rem;
}

.header-content-comment p {
      font-size: 1.1rem !important;
}

.my-header p {
      font-size: 1.8rem;
      margin-top: 0.8rem;
}

.my-section {
      padding: 3rem 2rem 1rem 2rem;
      text-align: center;
}

.how-it-works h2,
.why-join h2,
.join-now h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
      color: var(--lawn-green);
}

.steps,
.benefits {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 2rem;
}

.step,
.benefit {
      background-color: var(--eerie-black);
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 1.5rem;
      width: 30%;
      margin-bottom: 1rem;
}

.step h3,
.benefit h3 {
      color: var(--lawn-green);
      margin-bottom: 0.5rem;
}

.step p,
.benefit p {
      color: var(--text-color);
      margin-bottom: 1rem;
}

.join-now {
      padding: 6rem 2rem;
}

.join-now span {
      display: flex;
      justify-content: center;
}

.join-now span p {
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
      max-width: 70%;
      font-weight: 600;
      color: white;
}

.cta-button {
      background-color: var(--lawn-green);
      color: white;
      padding: 1rem 2.2rem;
      /* padding: 0.75rem 2rem; */
      font-size: 1.6rem;
      font-weight: 600;
      border-radius: 15px;
      text-decoration: none;
      transition: background-color 0.3s ease;
}

.cta-button:hover {
      background-color: rgb(156, 243, 63);
}

.my-footer {
      background-color: var(--eerie-black);
      color: var(--text-color);
      text-align: center;
      padding: 3rem 0;
      margin-top: 2rem;
}

.my-footer p {
      margin: 0;
}

.marked-lawn {
      color: var(--lawn-green);
}

.marked-yellow {
      color: #c1ef1d;
}

.marked-red {
      color: #ef1d38;
}

.inside-shadow {
      font-size: 1.2rem;
      box-shadow: inset 0px 0px 50px 10px var(--dim-gray);
      /* box-shadow: inset 0px 0px 50px 10px #2A9D8F; */
      padding: 2rem;
}